<template>
  <div>
    <div class="gray-header">
      <div class="header-box inner">
        <div class="header-user">
          <router-link to="/myInfo">
            <img src="@/assets/images/IA/user_icon.png" alt="">
            <p class="user-name" v-if="userName != null">{{ userName }}님</p>
          </router-link>
          <a style="color: #71717A" @click="isShowModal = true"><p>로그아웃</p></a>
        </div>
      </div>
    </div>
    <div class="white-header">
      <div class="header-box inner">
        <router-link to="/" class="header-logo">
          <img src="@/assets/images/IA/header_logo_1.png" alt="">
        </router-link>
        <img src="@/assets/images/IA/header_logo_2.png" alt="">
        <div class="hideBtn">
          <a href="http://www.nrc.go.kr/nrc/main.do" class="left" target="_blank"/>
          <a href="https://www.jj.ac.kr/ot/" class="right" target="_blank"/>
        </div>
      </div>
    </div>
    <div class="modal-cover" v-show="isShowModal"></div>
    <LogoutDialog v-if="isShowModal" @callBackModal="callBackModal"/>
  </div>
</template>

<script>
import LogoutDialog from "@/views/common/LogoutDialog";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "HeaderLogin",
  components: {LogoutDialog},
  data() {
    return {
      userName: '홍길동',
      isShowModal: false,
    }
  },
  mounted() {
    this.isLoginUser()
  },
  methods: {
    isLoginUser() {
      if (this.$store.state.user != null) {
        this.userName = this.$store.state.user.displayName
      }
    },
    callBackModal: function (index) {
      const self = this;
      if (index === 1) {
        self.isShowModal = false
      } else {
        self.isShowModal = false
        firebase.auth().signOut();
      }
    },
  }
}
</script>

<style scoped>

.gray-header {
  height: 48px;
  background: #F0F0F0;
}

.gray-header .header-box {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gray-header .header-box a {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-left: 40px;
}

.gray-header .header-box .header-user {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.gray-header .header-box .header-user a {
  display: flex;
  align-items: center;
}

.gray-header .header-box .header-user a img {
  margin-right: 9px;
  width: 16px;
  height: 16px;
}

.white-header {
  height: 120px;
}

.white-header .header-box {
  height: 100%;
  width: 1140px;
  margin: 0 auto;
  position: relative;
}

.white-header .header-box .header-logo {
  position: absolute;
  top: 41px;
}

.white-header .header-box .header-logo img {
  width: 314px;
  height: 59px;
}

.white-header .header-box > img {
  position: absolute;
  right: 0;
  top: 46px;
  width: 217px;
  height: 54px;
}
.white-header .header-box .hideBtn {
  position: absolute;
  display: flex;
  right: 0; top: 46px;
  width: 217px;
  height: 54px;
}
.white-header .header-box .hideBtn .left {
  width: 130px;
}
.white-header .header-box .hideBtn .right {
  width: 87px;
}
</style>
