<template>
  <div class="footer">
    <div class="modal-cover" v-show="isShowModal||isShowPrivateModal"></div>
    <div class="footer-box inner">
      <div class="footer-text">
        <div class="link">
          <a @click="isShowPrivateModal=true">
            서비스 이용약관
          </a>
          <a @click="isShowModal=true">
            개인정보 취급방침
          </a>
        </div>
        <div class="text">
          <p>Measure for Children’s Participation</p>
          <p>대표자 : 정윤화<br/>
          (55069) 전라북도 전주시 완산구 천잠로 303, 전주대학교 천잠관 ㅣ TEL : 063-220-2121<br/>
          Copyrights (C)2021 Jeonju University. All Rights Reserved.</p>
        </div>
      </div>
      <div class="footer-logo"><img src="@/assets/images/IA/footer_logo.png" alt=""></div>
    </div>

    <PrivateTerms v-show="isShowModal" @callBackModal="callBackModal"/>
    <ServiceTerms v-show="isShowPrivateModal" @callBackModal="callBackModal"/>
  </div>
</template>

<script>
import PrivateTerms from "@/views/terms/PrivateTerms.vue";
import ServiceTerms from "@/views/terms/ServiceTerms.vue";

export default {
  name: "Footer",
  components: {
    ServiceTerms,
    PrivateTerms,
  },
  data(){
    return{
      isShowModal: false,
      isShowPrivateModal : false,
    }
  },
  methods:{
    callBackModal: function (index) {
      const self = this;
      if (index === 0) {
        self.isShowModal = false
        self.isShowPrivateModal = false
      }
    },
  }
}
</script>

<style scoped>
.footer {
  background: #181818;
  height: 250px;
}
.footer-box {
  font-weight: 700;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
}
.footer-box .footer-text {
  padding-top: 34px;
  color: #71717a;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  padding-bottom: 63px;
  
}
.footer-box .footer-text .link a{
  color: #ffffff;
  margin-right: 27px;
}
.footer-box .footer-text .link :nth-child(2) {
  color: #F7D300;
}
.footer-box .footer-text .text :nth-child(1) {
  margin-bottom: 12px;
}
.footer-box .footer-text .text :nth-child(2) {
  line-height: 18px;
  font-weight: 400;
}
.footer-box .footer-logo { margin-top: 109px; }
.footer-box .footer-logo img {
  height: 54px;
  width: 217px;
}

</style>
